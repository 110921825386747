import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Blog.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Facebook from "../../Assests/facebook.png";
import Linkedin from "../../Assests/linkedin.png";
import Twitter from "../../Assests/twitterx.png";
import Whatsapp from "../../Assests/whatsapp.png";
import CopyLink from "../../Assests/copylink.png";
import { message } from "antd";
import { useParams, useNavigate } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const [blogData, setBlogData] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const { title } = useParams();

  const shareViaFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
      "_blank"
    );
  };

  const shareViaWhatsApp = () => {
    window.open(
      `whatsapp://send?text=${encodeURIComponent(window.location.href)}`,
      "_blank"
    );
  };

  const shareViaLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
      "_blank"
    );
  };

  const shareViaTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${window.location.href}`,
      "_blank"
    );
  };

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success("Link copied to clipboard");
  };

  const fetchBlogData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/blog/fetch-blog"
      );
      if (response.status === 200) {
        setBlogData(response.data.reverse());
      } else {
        console.error("Failed to fetch blog data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  useEffect(() => {
    if (!selectedBlog && blogData.length > 0) {
      setSelectedBlog(
        title
          ? blogData.find((blog) => blog.title === decodeURIComponent(title)) ||
              blogData[0]
          : blogData[0]
      );
    }
    scrollToTop();
  }, [selectedBlog, blogData, title]);

  const DataContent = ({ blogtitle, blogcontent }) => {
    return (
      <div className="blogcontentcard">
        <p className="blogtitle">{blogtitle}</p>
        <div className="blog-content">
          <p dangerouslySetInnerHTML={{ __html: blogcontent }} />
        </div>
        <div className="share-blog">
          <img
            src={Facebook}
            alt="shareViaFacebook"
            className="share-icon"
            onClick={shareViaFacebook}
          />
          <img
            src={Whatsapp}
            alt="shareViaWhatsApp"
            className="share-icon"
            onClick={shareViaWhatsApp}
          />
          <img
            src={Linkedin}
            alt="shareViaLinkedIn"
            className="share-icon"
            onClick={shareViaLinkedIn}
          />
          <img
            src={Twitter}
            alt="shareViaTwitter"
            className="share-icon"
            onClick={shareViaTwitter}
          />
          <img
            src={CopyLink}
            alt="copyLink"
            className="share-icon2"
            onClick={copyLink}
          />
        </div>
      </div>
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const BlogList = ({ cover, blog }) => {
    const isSelected = selectedBlog && selectedBlog.title === blog.title;

    const handleClick = () => {
      setSelectedBlog(blog);
    };

    return (
      <>
        <div
          className={`list-item ${isSelected ? "selected" : ""}`}
          onClick={handleClick}
        >
          <img src={blog.cover} className="blog-list-cover" alt="cover" />
          <label>{blog.title}</label>
        </div>
        <hr></hr>
      </>
    );
  };

  const BlogList2 = ({ cover, blog }) => {
    const isSelected = selectedBlog && selectedBlog.title === blog.title;

    const handleClick = () => {
      setSelectedBlog(blog);
      scrollToTop(); // Scroll to top when an extra blog is selected
    };

    return (
      <>
        <div
          className={`extra-list ${isSelected ? "selected" : ""}`}
          onClick={handleClick}
        >
        <img src={blog.cover} className="blog-list2-cover" alt="cover"/>
          <label>{blog.title}</label>
        </div>
      </>
    );
  };

  return (
    <div className="wrapperblog">
      <Navbar />
      <div className="blogpage">
        <div className="sub-label">
          <p>Blogs</p>
        </div>
        <div className="blog-display">
          <div className="main-content">
            {selectedBlog && (
              <DataContent
                className="cardnormaltext"
                blogtitle={selectedBlog.title}
                blogcontent={selectedBlog.content}
              />
            )}
          </div>

          <div className="all-blogs">
            <div className="upper">
              <h3>Read All Blogs</h3>
            </div>
            <div className="blog-list">
              {blogData.map((item, index) => (
                <BlogList key={index} blog={item} />
              ))}
            </div>
          </div>
        </div>
        <div className="sub-label">
          <p>Here Are Some More Blogs..!!</p>
        </div>
        <div className="extra-blogs">
          {blogData.slice(0, 6).map((item, index) => (
            <BlogList2 key={index} blog={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
