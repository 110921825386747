import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Journey.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Educationimg from '../../Assests/education.png';
import { useNavigate } from 'react-router-dom';

const Journey = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const [aboutData, setAboutData] = useState([]);

  const fetchaboutData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/about/fetch-about-data"
      );
      setAboutData(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  useEffect(() => {
    fetchaboutData();
  }, []);

  return (
    <div className="journeywrapper">
      <Navbar />
      <div className="journeycontent">
        <div className="container">
          <div className="sub-label">
            <p>Journey</p>
          </div>
          <div className="journey-data">
            {aboutData.map((item, index) =>
              item.title === "Journey" ? (
                <label
                  className=""
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              ) : null
            )}
          </div>
        </div>
        <div className="container">
          <div className="sub-label">
            <p>Education</p>
          </div>
          <div className="education-data">
            {aboutData.map((item, index) =>
              item.title === "Education" ? (
                <label
                  className="aboutcardnormaltext"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              ) : null
            )}
          </div>
        </div>
        <div className="educationpane">
          <img src={Educationimg} alt="education-icon" />
        </div>
        <div className="container">
          <div className="sub-label">
            <p>Childhood</p>
          </div>
          <div className="childhood-data">
            {aboutData.map((item, index) =>
              item.title === "Childhood" ? (
                <label
                  className="aboutcardnormaltext"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              ) : null
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Journey;
