import React, { useEffect, useState } from "react";
import "./Media.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from 'react-router-dom';

const Media = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const [videos, setFetchVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOverlayText, setSelectedOverlayText] = useState(""); // Define selectedOverlayText state

  const album = () => {
    window.location.href = "/album";
  }  

  const YoutubeClick = () => {
    window.open("https://www.youtube.com/@wematter9307", "_blank");
  };

  const fetchUploadedVideos = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/mediavideos/fetch-media-videos"
      );
      setFetchVideos(response.data.reverse());
    } catch (error) {
      console.error("Error fetching media videos:", error);
    }
  };

  useEffect(() => {
    fetchUploadedVideos();
  }, []);

  const MediaVideo = ({ video }) => {
    return (
      <div className="mediavideo">
        <iframe
          src={`https://www.youtube.com/embed/${video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const fetchUploadedImages = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/mediaphotos/fetch-media-photos"
      );
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching media photos:", error);
    }
  };

  useEffect(() => {
    fetchUploadedImages();
  }, []);

  const handleImageClick = (imageUrl, overlayText) => {
    setSelectedImage(imageUrl);
    setSelectedOverlayText(overlayText); // Set selectedOverlayText
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedImage(null);
    setSelectedOverlayText(""); // Reset selectedOverlayText
  };

  const renderImagesByPosition = (position) => {
    return images
      .filter((item) => item.position === position)
      .map((item, index) => (
        <div className="image-container" key={index} onClick={() => handleImageClick(item.image, item.description)}>
          <img
            src={item.image}
            alt={`icon ${item.position}`}
            className="media-img"
          />
          <div className="overlay">
            <h2 className="overlay-text">{item.description}</h2>
          </div>
        </div>
      ));
  };
  

  return (
    <div className="mediawrapper">
      <Navbar />
      <div className="mediatemplete">
        <section id="photos">
          <div className="sub-label">
            <p>Photos</p>
          </div>
          <div className="photosection">
            <div className="mediacolumnone">
              <div className="colone-rowone">{renderImagesByPosition("One")}</div>
              <div className="colone-rowtwo">
                {renderImagesByPosition("Two")}
              </div>
              <div className="colone-rowthree">
                {renderImagesByPosition("Three")}
              </div>
              <div className="colone-rowfour">
                {renderImagesByPosition("Four")}
              </div>
            </div>

            <div className="mediacolumntwo">
              <div className="coltwo-rowone">
                {renderImagesByPosition("Five")}
              </div>
              <div className="coltwo-rowtwo">
                {renderImagesByPosition("Six")}
              </div>
              <div className="coltwo-rowthree">
                {renderImagesByPosition("Seven")}
              </div>
            </div>

            <div className="mediacolumnthree">
              <div className="colthree-rowone">
                {renderImagesByPosition("Eight")}
              </div>
              <div className="colthree-rowtwo">
                {renderImagesByPosition("Nine")}
              </div>
              <div className="colthree-rowthree">
                {renderImagesByPosition("Ten")}
              </div>
              <div className="colthree-rowfour">
                {renderImagesByPosition("Eleven")}
              </div>
            </div>
          </div>
          <div className="more-button">
            <label onClick={album}>
              <Link>Watch More Photos</Link>
            </label>
          </div>
        </section>
        <br />
        <br />
        <section id="videos">
          <div className="sub-label">
            <p>Videos</p>
          </div>
          <div className="videosection">
            {videos.slice(0, 6).map((item, index) => (
              <MediaVideo key={index} video={item.video} />
            ))}
          </div>
          <div className="more-button">
            <label onClick={YoutubeClick}>
              <Link>Watch More Videos</Link>
            </label>
          </div>
        </section>
      </div>
      <Footer />
      {showPopup && (
        <div className="popup">
          <span className="close" onClick={handleClosePopup}>
            &times;
          </span>
          <div className="popup-inner">
            <img src={selectedImage} alt="Selected Image" className="popup-image-media" />
            <div className="popup-overlay-text">{selectedOverlayText}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Media;
