import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Consultingdetailed.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from 'react-router-dom';

const Consultingdetailed = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const [consultingdetailData, setConsultingDetailedData] = useState([]);

  const fetchConsultingDetailedData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/consulting-detailed/fetch-consult-detailed"
      );
      setConsultingDetailedData(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  useEffect(() => {
    fetchConsultingDetailedData(); // Fetch consulting data when component mounts
  }, []);

  const DataContent = ({ title, content }) => {
    return (
      <div className="detailedcontentcard">
        <p className="detailedtitle">{title}</p>
        <div className="detailedcontentarea">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div className="copyright">&copy; Copyright - Prashant Srivastava</div>
      </div>
    );
  };

  return (
    <div className="wrapperconsultingcontent">
      <Navbar />
      <div className="consultingcontent">
        {consultingdetailData.map((item, index) => (
          <section id={item.title} key={index}>
            <DataContent
              className="cardnormaltext"
              key={index}
              title={item.title}
              content={item.content}
            />
          </section>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Consultingdetailed;
