import "./App.css";
import Contact from "../src/Components/Contact/Contact";
import Family from "./Components/About/Family";
import Journey from "./Components/About/Journey";
import Consulting from "../src/Components/Consulting/Consulting";
import Media from "../src/Components/Media/Media";
import Blog from "../src/Components/Blog/Blog";
import Index from "../src/Components/Index/Index";
import Consultingdetails from "./Components/Consultingdetailed/Consultingdetailed";
import Album from "./Components/Album/Album";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about/family" element={<Family />} />
        <Route path="/about/journey" element={<Journey />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/media" element={<Media />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:title" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/consultingdetails" element={<Consultingdetails />} />
        <Route path="/album" element={<Album />} />
      </Routes>
    </Router>
  );
}

export default App;
