import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Family.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from 'react-router-dom';

const Family = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const [aboutData, setAboutData] = useState([]);
  const [memberData, setMemberData] = useState([]);

  const fetchaboutData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/about/fetch-about-data"
      );
      setAboutData(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  useEffect(() => {
    fetchaboutData();
    fetchFamilyMember();
  }, []);

  const fetchFamilyMember = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/familymember/fetch-family-member"
      );
      setMemberData(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  return (
    <div className="familywrapper">
      <Navbar />
      <div className="journeycontent">
        <div className="data-container">
          <div className="sub-label">
            <p>Family</p>
          </div>
          <div className="familycontent">
            {aboutData.map((item, index) =>
              item.title === "family" ? (
                <label
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              ) : null
            )}
          </div>
        </div>
        <div className="data-container">
          <div className="sub-label">
            <p>Parents</p>
          </div>
          <div className="familtcontent1">
            <div className="side-one">
              <div className="photo-type1">
                {memberData.map((item, index) =>
                  item.member === "Brijendra Srivastava" ? (
                    <img
                      src={item.image}
                      alt={`${item.member} icon`}
                      className="family-photo"
                    />
                  ) : null
                )}
              </div>
              <h3>Brijendra Srivastava</h3>
              <h4>Father</h4>
              {/* <div className='aboutfather'>
                <label>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </label>
              </div> */}
            </div>
            <div className="side-two">
              <div className="photo-type1">
                {memberData.map((item, index) =>
                  item.member === "Raman Srivastava" ? (
                    <img
                      src={item.image}
                      alt={`${item.member} icon`}
                      className="family-photo"
                    />
                  ) : null
                )}
              </div>
              <h3>Raman Srivastava</h3>
              <h4>Mother</h4>
            </div>
          </div>
        </div>
        <div className="data-container">
          <div className="sub-label">
            <p>Spouse</p>
          </div>
          <div className="familtcontent2">
            <div className="photo-type2">
              {memberData.map((item, index) =>
                item.member === "Sarita Srivastava" ? (
                  <img
                    src={item.image}
                    alt={`${item.member} icon`}
                    className="family-photo2"
                  />
                ) : null
              )}
            </div>
            <h3>Sarita Srivastava</h3>
            <h4>Spouse</h4>
          </div>
        </div>
        <div className="data-container">
          <div className="sub-label">
            <p>Children</p>
          </div>
          <div className="familtcontent1">
            <div className="side-one">
              <div className="photo-type1">
                {memberData.map((item, index) =>
                  item.member === "Varun Srivastava" ? (
                    <img
                      src={item.image}
                      alt={`${item.member} icon`}
                      className="family-photo"
                    />
                  ) : null
                )}
              </div>
              <h3>Varun Srivastava</h3>
              <h4>Son</h4>
            </div>
            <div className="side-two">
              <div className="photo-type1">
                {memberData.map((item, index) =>
                  item.member === "Shourya Srivastava" ? (
                    <img
                      src={item.image}
                      alt={`${item.member} icon`}
                      className="family-photo"
                    />
                  ) : null
                )}
              </div>
              <h3>Shourya Srivastava</h3>
              <h4>Son</h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Family;
