import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./Index.css";
import PlayIcon from "../../Assests/play.png";
import PauseIcon from "../../Assests/pause.png";
import { HashLink as Link } from "react-router-hash-link";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward, faBackward } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';


const Index = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);


  const carouselRef = useRef(null);
  const [currentSound, setCurrentSound] = useState(null);
  const [blogdata, setBlogData] = useState([]);
  const [videos, setFetchVideos] = useState([]);
  const [sliderimage, setSliderImage] = useState([]);
  const [podcastaudio, setPodcastAudio] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [companylogo, setCompanyLogo] = useState([]);

  const fetchFamilyMember = async () => {
    try {
      const response = await axios.get("https://api-prashant.we-matter.xyz/api/v1/familymember/fetch-family-member");
      setMemberData(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const fetchcompany = async () => {
    try {
      const response = await axios.get("https://api-prashant.we-matter.xyz/api/v1/company/fetch-company-data");
      setCompanyLogo(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const playSound = (sound) => {
    if (currentSound && currentSound.src === sound.src) {
      currentSound.pause();
      setCurrentSound(null);
    } else {
      if (currentSound) {
        currentSound.pause();
      }
      sound.play();
      setCurrentSound(sound);
    }
  };

  const fetchslider = async () => {
    try {
      const response = await axios.get("https://api-prashant.we-matter.xyz/api/v1/homeslider/fetch-home-slider");
      setSliderImage(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const fetchhomeblog = async () => {
    try {
      const response = await axios.get("https://api-prashant.we-matter.xyz/api/v1/blog/fetch-blog");
      setBlogData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const fetchHomeVideos = async () => {
    try {
      const response = await axios.get("https://api-prashant.we-matter.xyz/api/v1/homevideos/fetch-home-videos");
      setFetchVideos(response.data); // Update images state with fetched data
    } catch (error) {
      console.error("Error fetching home videos:", error);
    }
  };

  const Videocard = ({ video }) => {
    return (
      <div className="videocard">
        <iframe
          src={`https://www.youtube.com/embed/${video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const fetchpodcast = async () => {
    try {
      const response = await axios.get("https://api-prashant.we-matter.xyz/api/v1/homepodcast/fetch-home-podcast");
      setPodcastAudio(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  useEffect(() => {
    fetchhomeblog();
    fetchHomeVideos();
    fetchslider();
    fetchpodcast();
    fetchFamilyMember();
    fetchcompany();
  }, []);

  const YoutubeClick = () => {
    window.open("https://www.youtube.com/@wematter9307", "_blank");
  };

  const visitwematter = () => {
    window.open("https://we-matter.com/", "_blank");
  };

  const handleNextClick = () => {
    carouselRef.current.slideNext();
  };

  const handlePrevClick = () => {
    carouselRef.current.slidePrev();
  };

  return (
    <>
      <div className="indexwrapper">
        <Navbar />
        <div className="facecontent">
          <div className="parentcontainer">
            <div className="container1">
              <label className="outertext">
                <div></div>
                I'm <label className="innertext">Prashant Srivastava</label>.
                <br></br>
                CEO on a mission
              </label>
              <br />
              <br />
              <label className="sub-text">
                CEO at WE-Matter | Ex CEO Gallup India | Ex Partner Aon Hewitt
                Engagement and Culture
                <br />
                Top 10 Global Employee Engagement Consultants
              </label>
            </div>
            <div className="container2">
              <div className="circle1"></div>
                <div className="circle2">
                <div className="circle3"></div></div>
                <div className="header-photo-div">
                  {memberData.map((item, index) =>
                    item.member === "Prashant Srivastava" ? (
                      <img
                        src={item.image}
                        alt={`${item.member} icon`}
                        className="header-photo"
                      />
                    ) : null
                  )}
              </div>
              <div className="bullet1">
                <label className="bullettext1"></label>
                <br />
                <label className="bullettext2"></label>
              </div>
              <div className="bullet2">
                <label className="bullettext1"></label>
                <br />
                <label className="bullettext2"></label>
              </div>
            </div>
          </div>
          <div className="indexpagecontent">
          <div className="slide-img-container">
            <AliceCarousel
              autoPlay
              autoPlayInterval={2000}
              infinite
              buttonsDisabled
              ref={(el) => (carouselRef.current = el)}
            >
              {sliderimage.map((item, index) => (
                <div key={index} className="slide">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="imagestyle"
                  />
                  <div className="text-container">
          <span className="textstyle">{item.title}</span>
        </div>
                </div>
              ))}
            </AliceCarousel>
            <FontAwesomeIcon
              icon={faBackward}
              size="2xl"
              style={{ color: "#ffffff" }}
              alt="Previous"
              className="prev"
              onClick={handlePrevClick}
            />
            <FontAwesomeIcon
              icon={faForward}
              size="2xl"
              style={{ color: "#ffffff" }}
              alt="Next"
              className="next"
              onClick={handleNextClick}
            />
          </div>
          
            <div className="infocontainer">
              <div className="sub-label">
                <p>My Company</p>
              </div>
              <div>
                {companylogo.map((item, index) => (
                  <div key={index} className="companycontent">
                    <div className="logo-container">
                      <img
                        src={item.logo}
                        alt={`Logo ${index + 1}`}
                        className="companyicon"
                      />
                    </div>
                    <div className="companydescription">
                      <h4>{item.description}</h4>
                      <label className="visitwematter" onClick={visitwematter}>
                        Visit We-Matter
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="infocontainer">
              <div className="sub-label">
                <p>Blog</p>
              </div>
              <div className="cardcontainers">
                {blogdata.slice(0, 3).map((item, index) => (
                  <a
                    href={`/blog/${encodeURIComponent(item.title)}`}
                    key={index}
                  >
                    <div className="blogcard">
                      <div className="blog-img-container">
                        <img
                          src={item.cover}
                          alt={`${item.title} icon`}
                          className="img-blog"
                        />
                      </div>
                      <h2>{item.title}</h2>
                    </div>
                  </a>
                ))}
              </div>
              <div className="more-button">
                <label>
                  <Link to="/blog">Read More Blogs</Link>
                </label>
              </div>
            </div>
            <div className="infocontainer">
              <div className="sub-label">
                <p>Videos</p>
              </div>
              <div className="cardcontainers">
                {videos.slice(0, 3).map((item, index) => (
                  <Videocard key={index} video={item.video} />
                ))}
              </div>
              <div className="more-button">
                <label onClick={YoutubeClick}>
                  <Link>Watch More Videos</Link>
                </label>
              </div>
            </div>
            <div className="infocontainer">
              <div className="sub-label">
                <p>Podcast</p>
              </div>
              <div className="cardcontainers2">
                {podcastaudio.map((item, index) => (
                  <div className="podcastcard" key={index}>
                    <div className="podcastcontent">
                      <label>{item.title}</label>
                    </div>
                    <div className="playbutton">
                      <img
                        src={
                          currentSound && currentSound.src === item.audio
                            ? PauseIcon
                            : PlayIcon
                        }
                        onClick={() => playSound(new Audio(item.audio))}
                        alt="play button pic"
                        className="play-button-img"
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="more-button">
                <label>
                  <Link>Listen More Podcasts</Link>
                </label>
              </div>
            </div>
          </div>
          <div className="infocontainer">
            <div className="sub-label"></div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
