import React, { useState } from "react";
import "./Footer.css";
import Facebook from "../../Assests/facebook.png";
import Instagram from "../../Assests/instagram.png";
import Linkedin from "../../Assests/linkedin.png";
import Twitterx from "../../Assests/twitterx.png";
import Newsheadimg from "../../Assests/newsletterimg2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked); // Toggle the state when the button is clicked
  };

  const FacebookClick = () => {
    window.open("https://www.facebook.com/PrashantBKS/ ", "_blank");
  };

  const LinkedinClick = () => {
    window.open("https://www.linkedin.com/in/prashantbsrivastava/", "_blank");
  };

  const InstagramClick = () => {
    window.open("https://www.instagram.com/prashant_wematter/?hl=en", "_blank");
  };

  const TwitterxClick = () => {
    
  };

  return (
    <div className="wrapperfooter">
      <div className="footercontent">
        <div className="Address">
          <h2>Address</h2>
          <div>
            <label className="addresslabel">
              Office 12, SPTBI, Bhavan's College Main Entrance, next to Indian
              Bank, Old D N Nagar, Munshi Nagar, Andheri West, Mumbai,
              Maharashtra 400058
            </label>
          </div>
        </div>
        <div className="socialtab">
          <h2>Social</h2>
          <div className="socialicons">
            <img
              src={Facebook}
              onClick={FacebookClick}
              alt="blog 3 pic"
              className="img-social"
            />
            <img
              src={Linkedin}
              onClick={LinkedinClick}
              alt="blog 3 pic"
              className="img-social"
            />
            <img
              src={Instagram}
              onClick={InstagramClick}
              alt="blog 3 pic"
              className="img-social"
            />
            <img
              src={Twitterx}
              onClick={TwitterxClick}
              alt="blog 3 pic"
              className="img-social"
            />
          </div>
        </div>
        <div className="newsletter">
          <div className="newsletterheadimg">
            <div className="headimg">
              <img src={Newsheadimg} alt="blog 3 pic" className="img-head" />
            </div>
            <div className="headtext">
              <label className="firstline">
                <FontAwesomeIcon icon={faNewspaper} /> NEWSLETTER
              </label>
              <br></br>
              <label className="secondline">The Passion Project</label>
              <br></br>
              <label>Sharing the Journey, Living the Dream!</label>
            </div>
          </div>
          <label className="sub-text-footer">
            Know Me More - Subscribe The Newsletter
          </label>
          <div className="button1">
            <button onClick={handleClick}>
              {/* Conditionally render the icon and label based on the state */}
              {isClicked ? (
                <>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 11.917 9.724 16.5 19 7.5"
                    />
                  </svg>
                  Subscribed
                </>
              ) : (
                <>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12h14m-7 7V5"
                    />
                  </svg>
                  Subscribe
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="copywright">
        <label>Copyright @2024 Prashant Srivastava</label>
      </div>
    </div>
  );
};

export default Footer;
