import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Album.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from 'react-router-dom';

const Album = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const [albumData, setAlbumData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [expandedAlbums, setExpandedAlbums] = useState({});

  useEffect(() => {
    fetchAlbum(); // Fetch data when component mounts
  }, []);

  const fetchAlbum = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/album/fetch-album"
      );
      setAlbumData(response.data || []); // Ensure albumData is an array even if response.data is undefined
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedImage(null);
  };

  const handleToggle = (index) => {
    setExpandedAlbums((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div className="consultingwrapper">
      <Navbar />
      <div className="albumpage">
        <div className="sub-label">
          <p>Albums</p>
        </div>
        {albumData.map((item, index) => (
          <div key={index} className={`grid-item ${expandedAlbums[index] ? 'expanded' : ''}`}>
            <div className="album-heading">
              <h2>{item.title}</h2>
            </div>
            <div className={`file-display ${expandedAlbums[index] ? 'expanded' : ''}`}>
              {item.images &&
                item.images.map((file, fileIndex) => (
                  <img
                    key={fileIndex}
                    src={file}
                    alt={`File ${fileIndex}`}
                    className="album-images"
                    onClick={() => handleImageClick(file)}
                  />
                ))}
            </div>
            
            <label className="show-more-btn" onClick={() => handleToggle(index)}>
              {expandedAlbums[index] ? (
                <span dangerouslySetInnerHTML={{ __html: 'Show Less ⮝' }} />
              ) : (
                <span dangerouslySetInnerHTML={{ __html: 'Explore The Entire Album ⮟' }} />
              )}
            </label>



          </div>
        ))}
      </div>
      {showPopup && (
        <div className="popup">
          <span className="close" onClick={handleClosePopup}>
            &times;
          </span>
          <img src={selectedImage} alt="Selected File" className="popup-image" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Album;
