import React, { useState, useRef, useEffect } from "react";
import "./Contact.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from 'react-router-dom';


const Contact = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    companyname: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contact: "",
    companyname: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Clear error message when user starts typing or corrects the input
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = async (e) => {
    e.preventDefault();
  
    const contactPattern = /^[1-9]\d{9}$/;
    const namePattern = /^(\w+\s){1,2}\w+$/;
    const emailPattern = /\S+@\S+\.\S+/;
    const companynamePattern = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/;
    const messagePattern = /^[\w\s\S]{1,1000}$/;
  
    // Reset errors
    setErrors({
      name: "",
      email: "",
      contact: "",
      companyname: "",
      message: "",
    });
  
    let hasErrors = false;
  
    // Name validation
    if (formData.name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name cannot be empty.",
      }));
      hasErrors = true;
    } else if (!namePattern.test(formData.name.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is not valid.",
      }));
      hasErrors = true;
    }
  
    // Email validation
    if (formData.email.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email cannot be empty.",
      }));
      hasErrors = true;
    } else if (!emailPattern.test(formData.email.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is not valid.",
      }));
      hasErrors = true;
    }
  
    // Contact validation
    if (formData.contact.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact: "Contact cannot be empty.",
      }));
      hasErrors = true;
    } else if (!contactPattern.test(formData.contact.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contact: "Contact number should be exactly 10 digits.",
      }));
      hasErrors = true;
    }
  
    // Company name validation
    if (formData.companyname.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyname: "Company name cannot be empty.",
      }));
      hasErrors = true;
    } else if (!companynamePattern.test(formData.companyname.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyname: "Company name should not be more than 1000 digit.",
      }));
      hasErrors = true;
    }
  
    // Message validation
    if (formData.message.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "Message cannot be empty.",
      }));
      hasErrors = true;
    } else if (!messagePattern.test(formData.message.trim())) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: "Message should not be more than 1000 digits",
      }));
      hasErrors = true;
    }
  
    if (!hasErrors) {
      try {
        const response = await axios.post("https://api-prashant.we-matter.xyz/api/v1/contact/fill-contact", formData);
        if (response.status === 201) {
          console.log("Data saved successfully!");
          message.success("Details sent successfully! We will be in touch with you shortly 😊");
          // Clear form data
          setFormData({
            name: "",
            email: "",
            contact: "",
            companyname: "",
            message: "",
          });
        } else {
          console.error("Failed to sent data");
          message.error("Failed to sent data");
        }
      } catch (error) {
        console.error("Error:", error);
        message.error("Failed to sent data");
      }
    }
  };
  
  

  return (
    <>
      <div className="wrappercontact">
        <Navbar />
        <div className="formcontent">
          <div className="sub-label">
            <p>Contact Me</p>
          </div>
          <div className="formdiv">
            <form ref={form} onSubmit={validate}>
              <div className="form-group">
                <div className="part1">
                  <label>
                    Name<sup>*</sup>
                  </label>
                  <div className="innerdiv">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Your Name"
                    />
                  </div>
                </div>
                <div className="part2">
                  <span className="errorclass" id="errorname">
                    {errors.name}
                  </span>
                </div>
              </div>

              <div className="form-group">
                <div className="part1">
                  <label>
                    Email<sup>*</sup>
                  </label>
                  <div className="innerdiv">
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div className="part2">
                  <span className="errorclass" id="erroremail">
                    {errors.email}
                  </span>
                </div>
              </div>

              <div className="form-group">
                <div className="part1">
                  <label>
                    Contact<sup>*</sup>
                  </label>
                  <div className="innerdiv">
                    <input
                      type="text"
                      name="contact"
                      value={formData.contact}
                      onChange={handleInputChange}
                      placeholder="Your Contact"
                    />
                  </div>
                </div>
                <div className="part2">
                  <span className="errorclass" id="errorcontact">
                    {errors.contact}
                  </span>
                </div>
              </div>

              <div className="form-group">
                <div className="part1">
                  <label>
                    Company Name<sup>*</sup>
                  </label>
                  <div className="innerdiv">
                    <input
                      type="text"
                      name="companyname"
                      value={formData.companyname}
                      onChange={handleInputChange}
                      placeholder="Your Company Name"
                    />
                  </div>
                </div>
                <div className="part2">
                  <span className="errorclass" id="errorcompanyname">
                    {errors.companyname}
                  </span>
                </div>
              </div>

              <div className="form-group2">
                <div className="part1">
                  <label>
                    Message<sup>*</sup>
                  </label>
                  <div className="innerdiv">
                    <textarea
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      placeholder="Message"
                    />
                  </div>
                </div>
                <div className="part2">
                  <span className="errorclass" id="errormessage">
                    {errors.message}
                  </span>
                </div>
              </div>

              <div className="button2">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
