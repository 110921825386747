import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Consulting.css";
import { HashLink as Link } from "react-router-hash-link";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from 'react-router-dom';


const Consulting = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    navigate({ unlisten });
    return () => {
      navigate({ unlisten: undefined });
    };
  }, [navigate]);

  const [consultingData, setConsultingData] = useState([]);

  const fetchConsultingData = async () => {
    try {
      const response = await axios.get(
        "https://api-prashant.we-matter.xyz/api/v1/consulting/fetch-consult"
      );
      setConsultingData(response.data);
    } catch (error) {
      console.error("Error fetching consulting data:", error);
    }
  };

  useEffect(() => {
    fetchConsultingData();
  }, []);

  return (
    <div className="consultingwrapper">
      <Navbar />
      <div className="sub-label">
            <p>Consulting Models</p>
          </div>
      <div className="cardsection">
        {consultingData.map((item, index) => (
          <section id={item.title} key={index}>
            <div className="consultingcard">
              <div className="consultingimg">
                <img
                  src={item.image}
                  alt={`${item.title} icon`}
                  className="img-consulting"
                />
              </div>
              <div className="consultingtext">
                <h2>{item.title}</h2>
                <div className="consulting-card-text">
                  <label>{item.description}</label>
                </div>
                <div className="continuebutton">
                  <Link smooth to={`/consultingdetails#${item.title}`}>
                    <label>Continue Reading</label>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Consulting;
