import React, { useState } from "react";
import "./Navbar.css";
import { HashLink as Link } from "react-router-hash-link";

const Navbar = () => {
  const [dropdownStates, setDropdownStates] = useState({
    aboutMe: false,
    consultingModel: false,
    media: false
  });

  const toggleDropdown = (dropdown) => {
    setDropdownStates(prevState => ({
      ...prevState,
      [dropdown]: !prevState[dropdown]
    }));
  };

  const closeAllDropdowns = () => {
    setDropdownStates({
      aboutMe: false,
      consultingModel: false,
      media: false
    });
  };

  // const adjustContentMargin = () => {
  //   const openedMenu = document.querySelector('.nav ul li.open');
  //   const contentBelowMenu = document.querySelector('.content-below-menu');

  //   if (openedMenu && contentBelowMenu) {
  //     const openedMenuHeight = openedMenu.offsetHeight;
  //     contentBelowMenu.style.marginTop = openedMenuHeight + 'px';
  //   }
  // };

  const uncheckCheckbox = () => {
    const checkbox = document.getElementById('check');
    if (checkbox) {
      checkbox.checked = false;
    }
  };

  return (
    <div className="navcontainer">
      <input type="checkbox" name="check" id="check" />
      <div className="logo-container">
        <Link to="/" className="logo">Prashant Srivastava</Link>
      </div>

      <div className="nav-btn">
        <div className="nav-links">
          <ul>
            <li className="nav-link" style={{ "--i": ".1s" }} onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>
              <Link to="/" activeClassName="active">Home</Link>
            </li>
            <li className="nav-link" style={{ "--i": ".3s" }}>
              <Link href="#" onClick={() => {toggleDropdown('aboutMe');}} activeClassName="active">
                About Me<i className="fas fa-caret-down"></i>
              </Link>
              <div className={`dropdown ${dropdownStates.aboutMe ? 'open' : ''}`}>
                <ul>
                  <li className="dropdown-link">
                    <Link to="/about/journey" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Journey</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link to="/about/family" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Family</Link>
                  </li>
                  <div className="arrow"></div>
                </ul>
              </div>
            </li>
            <li className="nav-link" style={{ "--i": ".5s" }} onClick={() => {closeAllDropdowns();}}>
              <Link to="/consulting">Consulting Model<i className="fas fa-caret-down"></i></Link>
              <div className={`dropdown ${dropdownStates.consultingModel ? 'open' : ''}`}>
                <ul>
                  <li className="dropdown-link">
                    <Link smooth to="/consulting#Employee Engagement" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Employee Engagement</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link smooth to="/consulting#Well-Being" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Well-being</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link smooth to="/consulting#EVP" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>EVP</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link smooth to="/consulting#Leadership Model" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Leadership Model</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link smooth to="/consulting#Culture" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Culture</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link smooth to="/consulting#Careers" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Careers</Link>
                  </li>
                  <div className="arrow"></div>
                </ul>
              </div>
            </li>
            <li className="nav-link" style={{ "--i": ".7s" }} onClick={() => {closeAllDropdowns();}}>
              <Link to="/media">Media<i className="fas fa-caret-down"></i></Link>
              <div className={`dropdown ${dropdownStates.media ? 'open' : ''}`}>
                <ul>
                  <li className="dropdown-link">
                    <Link smooth to="/media#photos" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Photos</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link smooth to="/media#videos" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Videos</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link to="/blog" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Blogs</Link>
                  </li>
                  <li className="dropdown-link">
                    <Link to="/album" onClick={() => {closeAllDropdowns(); uncheckCheckbox();}}>Albums</Link>
                  </li>
                  <div className="arrow"></div>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div className="log-sign">
          <Link to="/contact" className="btn solid">Let's Talk</Link>
        </div>
      </div>
      <div className="hamburger-menu-container">
        <div className="hamburger-menu">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
